:root {
    --main-blue: #10455b;
    --main-yellow: #ffaf20;
    --main-gray: #f9f3ed;
    --main-white: #ffffff;
    --landing-bg: #191E24;

}
.dark {
    --landing-bg: #191E24;
  }
  
  /* Light theme */
  .light {
    --landing-bg: #F2F2F2;
  }