@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./assets/styles/globals.css";
:root {
    --my-color: #fff;
    --main-blue: #10455b;
    --main-yellow: #ffaf20;
    --main-gray: #f9f3ed;
    --main-white: #ffffff;
    /* or any other variables/style */
}
[data-theme="dark"] {
    --my-color: #978d8d;
}
[data-theme="pink"] {
    --my-color: #ffabc8;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--my-color);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.badge-secondary-custom {
    background-color: #ffaf20;
}

