.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}
.datePicker {
    display: inline-flex;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    appearance: none;
    height: 3rem;
    min-height: 3rem;
    padding-left: 1rem;
    padding-right: 2.5rem;
    font-size: 1rem;
    border: 1px solid rgb(206, 212, 218);
    border-radius: 0.25rem;
    max-width: 10rem;
    width: 100%;
}
.react-datepicker__input-container input {
    max-width: 10rem;
    width: 100%;
}
.mySelect {
    height: 20px !important;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.loadingAnimation {
    width: 65px;
    aspect-ratio: 1;
    position: relative;
}
.loadingAnimation:before,
.loadingAnimation:after {
    content: "";
    position: absolute;
    border-radius: 50px;
    box-shadow: 0 0 0 3px inset var(--fallback-bc, oklch(var(--bc) / 1));
    animation: l5 2.5s infinite;
}
.loadingAnimation:after {
    animation-delay: -1.25s;
    border-radius: 0;
}
@keyframes l5 {
    0% {
        inset: 0 35px 35px 0;
    }
    12.5% {
        inset: 0 35px 0 0;
    }
    25% {
        inset: 35px 35px 0 0;
    }
    37.5% {
        inset: 35px 0 0 0;
    }
    50% {
        inset: 35px 0 0 35px;
    }
    62.5% {
        inset: 0 0 0 35px;
    }
    75% {
        inset: 0 0 35px 35px;
    }
    87.5% {
        inset: 0 0 35px 0;
    }
    100% {
        inset: 0 35px 35px 0;
    }
}

.icon-color {
    fill: var(--fallback-bc, oklch(var(--bc) / 1));
}

@media (max-width: 639px) {
    .table-container table tbody tr td,
    .table-container table thead tr th {
        display: block;
        width: 50%;
        float: left;
    }
}

