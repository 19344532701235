@import "variables.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    --p: 36.77% 0.065 230.57;
    --s: 81.13% 0.1649894376435102 74.50109215205846;
}

* {
    padding: 0;
    margin: 0;
    border: 0;
    box-sizing: border-box;
}

input {
    outline: none;
    background-color: transparent;
}

.rcsd.rcsd-dropdown {
    width: 100% !important;
}

.rcsd-input {
    border: unset !important;
    padding: 4px;

    .icon {
        top: 30% !important;
        right: 15px !important;
    }

    .clear {
        top: 30% !important;
        right: 40px !important;
    }
}

:root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;
    --landing-bg: #191e24;
    --logo-url: url("../images/LogoDarkTheme.png");
    --optimize-url: url("../images/optimize.svg");
    --secure-url: url("../images/secure.svg");
    --monitor-url: url("../images/monitor.svg");
    --whiteTextes: #ffffff;
    --blackTextes: #191e24;
    --blueWhite: #ffffff;
}

[data-theme="dark"] {
    --landing-bg: #191e24;
    --logo-url: url("../images/LogoDarkTheme.png");
    --optimize-url: url("../images/optimize.svg");
    --secure-url: url("../images/secure.svg");
    --monitor-url: url("../images/monitor.svg");
    --whiteTextes: #ffffff;
    --blackTextes: #191e24;
    --blueWhite: #ffffff;
}

[data-theme="light"] {
    --landing-bg: #f2f2f2f2;
    --logo-url: url("../images/LogoWhiteTheme.png");
    --optimize-url: url("../images/optimizeLight.svg");
    --secure-url: url("../images/secureLight.svg");
    --monitor-url: url("../images/monitorLight.svg");
    --whiteTextes: #191e24;
    --blackTextes: #ffffff;
    --blueWhite: #10455b;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

@media (prefers-color-scheme: dark) {
    :root {
        --foreground-rgb: 255, 255, 255;
        --background-start-rgb: 0, 0, 0;
        --background-end-rgb: 0, 0, 0;
    }
}

.loading-spinner {
    pointer-events: none;
    position: relative;
    left: 48%;
    aspect-ratio: 1 / 1;
    width: 1.5rem;
    background-color: currentColor;
    mask-size: 100%;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-image: url("data:image/svg+xml,%3Csvg width=%2724%27 height=%2724%27 stroke=%27%23000%27 viewBox=%270 0 24 24%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cstyle%3E.spinner_V8m1%7Btransform-origin:center;animation:spinner_zKoa 2s linear infinite%7D.spinner_V8m1 circle%7Bstroke-linecap:round;animation:spinner_YpZS 1.5s ease-out infinite%7D%40keyframes spinner_zKoa%7B100%25%7Btransform:rotate%28360deg%29%7D%7D%40keyframes spinner_YpZS%7B0%25%7Bstroke-dasharray:0 150;stroke-dashoffset:0%7D47.5%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-16%7D95%25%2C100%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-59%7D%7D%3C%2Fstyle%3E%3Cg class=%27spinner_V8m1%27%3E%3Ccircle cx=%2712%27 cy=%2712%27 r=%279.5%27 fill=%27none%27 stroke-width=%273%27%3E%3C%2Fcircle%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

.loading-spinnerSendButton {
    pointer-events: none;
    position: relative;
    aspect-ratio: 1 / 1;
    width: 1.5rem;
    background-color: currentColor;
    mask-size: 100%;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-image: url("data:image/svg+xml,%3Csvg width=%2724%27 height=%2724%27 stroke=%27%23000%27 viewBox=%270 0 24 24%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cstyle%3E.spinner_V8m1%7Btransform-origin:center;animation:spinner_zKoa 2s linear infinite%7D.spinner_V8m1 circle%7Bstroke-linecap:round;animation:spinner_YpZS 1.5s ease-out infinite%7D%40keyframes spinner_zKoa%7B100%25%7Btransform:rotate%28360deg%29%7D%7D%40keyframes spinner_YpZS%7B0%25%7Bstroke-dasharray:0 150;stroke-dashoffset:0%7D47.5%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-16%7D95%25%2C100%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-59%7D%7D%3C%2Fstyle%3E%3Cg class=%27spinner_V8m1%27%3E%3Ccircle cx=%2712%27 cy=%2712%27 r=%279.5%27 fill=%27none%27 stroke-width=%273%27%3E%3C%2Fcircle%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f27777;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #9bf57d;
}

input:focus + .slider {
    box-shadow: 0 0 1px #9bf57d;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.dashboardBtn {
    width: 80%;
    height: 45px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin: 0 auto;
    margin-bottom: 40px;
    background-color: var(--blueWhite);
    color: var(--blackTextes);
    font-family: Segoe UI;
    font-size: 16px;
    font-weight: 600;
}

.logo-burger {
    background-image: var(--logo-url);
    background-size: contain;
    background-repeat: no-repeat;
    width: 220px;
    height: 45px;
    margin-left: 12%;
    margin-bottom: 20px;
}
